<template>
  <Dialog
    v-model="useDialogStore().workspaceCustomDomainSettings.isOpen"
    size="small-hug"
    @open="onOpen"
    @close="() => useDialogStore().workspaceCustomDomainSettings.onClose()"
  >
    <template #header>
      <DialogHeader
        :title="useT('workspace.customDomain.linkToX', { value: useWorkspaceStore().integrations?.customDomain?.url })"
        show-close
        @close="useDialogStore().close('workspaceCustomDomainSettings')"
      />
    </template>

    <div>
      <div class="flex bg-gray-50 border border-gray-200 rounded-md mb-4 p-3 pr-5">
        <div class="flex items-center flex-1">
          <Avatar
            size="md"
            type="square"
            icon="globe-02-1"
            class="bg-white"
          />

          <div class="px-3 flex-1">
            <strong class="text-sm font-medium">
              {{ useWorkspaceStore().integrations?.customDomain?.url }}
            </strong>
            <br>
            <div class="text-gray-500 text-sm">
              {{ useT('workspace.customDomain.domainUrl') }}
            </div>
          </div>
          <Badge
            :type="isCustomDomainConnected ? 'success' : 'warning'"
            :text="isCustomDomainConnected ? useT('workspace.integrations.customDomain.connected') : useT('workspace.integrations.customDomain.notConnected')"
          />
        </div>
      </div>

      <div v-loading="isDomainChecking">
        <div class="mb-4">
          <div class="font-semibold mb-0.5">
            {{ useT('workspace.customDomain.instructions.title') }}
          </div>
          <p class="text-sm text-gray-700 mb-3">
            {{ useT('workspace.customDomain.instructions.content') }}
          </p>

          <el-table
            class="border-t-0"
            :data="[
              subdomain ? {
                type: 'CNAME',
                name: subdomain,
                value: 'cname.vercel-dns.com',
              } : {
                type: 'A',
                name: '@',
                value: '76.76.21.21',
              },

              ...((domainCheck?.verification || []).map((item) => ({
                type: item.type,
                name: item.domain.replace(`.${domainCheck?.apexName}`, ''),
                value: item.value,
              }))),
            ]"
            cell-class-name="cursor-pointer"
            @cell-click="onCellClick"
          >
            <el-table-column
              prop="type"
              :label="useT('common.type')"
              width="100px"
            />
            <el-table-column
              prop="name"
              :label="useT('common.title')"
              width="140px"
            />
            <el-table-column
              prop="value"
              :label="useT('common.value')"
              class-name="[&_.cell]:whitespace-normal [&_.cell]:break-all"
            />
          </el-table>

          <p
            v-if="!subdomain"
            class="text-sm text-gray-500 mt-3 mb-3"
          >
            {{ useT('workspace.customDomain.dns.aRecord') }}
          </p>
        </div>

        <Button
          type="regular-outline"
          @click="async () => {
            await checkCustomDomain();

            if (!isConnected || !isCustomDomainConnected) {
              useToast(useT('workspace.customDomain.waitingForConfirmation'));
            }
          }"
        >
          {{ useT('workspace.customDomain.checkStatus') }}
        </Button>
      </div>
    </div>
  </Dialog>
</template>

<script setup>
// Refs
const domainCheck = ref(null);
const isDomainChecking = ref(false);

// Computed
const isCustomDomainConnected = computed(() => domainCheck.value?.verified && !domainCheck.value?.config?.misconfigured);
const subdomain = computed(() => {
  const url = useWorkspaceStore().integrations?.customDomain?.url;
  if (!url) {
    return null;
  }

  const apexDomain = url.split('.').slice(-2).join('.');
  const subdomain = url.includes(`.${apexDomain}`) ? url.replace(`.${apexDomain}`, '') : null;

  return subdomain;
});

// Methods
const onOpen = () => {
  checkCustomDomain();
};
const onCellClick = (row, column) => {
  useClipboard().copy(row[column.property]);
  useToast(useT('common.copied'));
};

const checkCustomDomain = async() => {
  const isConnected = isCustomDomainConnected.value;

  isDomainChecking.value = true;
  domainCheck.value = await useWorkspaceStore().checkCustomDomain();
  isDomainChecking.value = false;

  if (!isConnected && isCustomDomainConnected.value) {
    useToast(useT('workspace.customDomain.linked'));
    useDialogStore().close('workspaceCustomDomainSettings');
  }
};
</script>
